import { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressRaw, ValidatedProfilerValue } from '../ProfilerCard';
import { SocialLink } from '../SocialLink';

import { FormattedLineProps } from './types';
import { ADDRESS_RAW_KEYS, LINKS_KEY, getChunks, getMatch } from './utils';

export const FormattedLine: FunctionComponent<FormattedLineProps> = ({ data, highlights }) => {
  const { t } = useTranslation();
  const { key, level, parentHash, value, isValid, title } = data;

  let formattedValue: string | JSX.Element = value;
  let formattedKey: string | JSX.Element = key && key + ':';

  if (highlights && highlights.length) {
    const highlight = highlights.find(({ key }) => key === parentHash.replace(/^\./, ''));
    const search = highlights.find(({ key }) => !key);
    if (highlight || search) {
      const value = highlight?.value || search?.value;
      const operator = highlight?.operator || 'contains';
      const match = getMatch(formattedValue, value as string, operator);
      const chunks = match ? getChunks(formattedValue, match, operator) : null;
      if (chunks)
        formattedValue = (
          <>
            {chunks.map((chunk, index) => (
              <Fragment key={`${index}`}>
                {chunk}
                {index !== chunks.length - 1 && <span className="card-data-highlight">{match}</span>}
              </Fragment>
            ))}
          </>
        );
    }

    if (search) {
      const match = getMatch(formattedKey, search.value, 'contains');
      const chunks = match ? getChunks(formattedKey, match) : null;
      if (chunks)
        formattedKey = (
          <>
            {chunks.map((chunk, index) => (
              <Fragment key={`${index}`}>
                {chunk}
                {index !== chunks.length - 1 && <span className="card-data-highlight">{match}</span>}
              </Fragment>
            ))}
          </>
        );
    }
  }

  return (
    <span className="line" data-level={level}>
      <span
        className="key"
        style={{
          paddingLeft: level * 10,
        }}
      >
        {formattedKey}
      </span>
      <span className="value">
        {typeof isValid === 'boolean' && title ? (
          <ValidatedProfilerValue isValid={isValid} title={t(title)}>
            {formattedValue}
          </ValidatedProfilerValue>
        ) : ADDRESS_RAW_KEYS.includes(parentHash) ? (
          <AddressRaw value={value}>{formattedValue}</AddressRaw>
        ) : LINKS_KEY.includes(key) ? (
          <SocialLink url={value}>{formattedValue}</SocialLink>
        ) : (
          formattedValue
        )}
      </span>
    </span>
  );
};
