/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  EuiBadge,
  EuiButton,
  EuiCodeBlock,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiPopover,
  EuiSpacer,
  EuiSplitPanel,
  EuiText,
} from '@elastic/eui';
import { forwardRef, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { css } from '@emotion/css';
import cloneDeep from 'lodash.clonedeep';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ReactComponent as ActionIcon } from '@/assets/icons/flash.svg';
import { getTrustColor } from '../LeakProfilerData';
import { useIsOpenControl, usePrevious, useWindowSize } from '@/hooks';
import { LeakCountryBadge } from '../LeakCountryBadge';
import { CreateRecursiveSearchTask } from '../Modals';
import { useToolsPermission } from '../layouts/PermissionRequired';
import {
  CardData,
  FILTERED_BY_COUNTRY_KEY,
  FILTERED_BY_COUNTRY_CODE,
  Lines,
  PROFILER_VALIDATED_KEYS,
  filterData,
  getLines,
  Highlight,
} from '../CardData';

import { SpanHighLightsCode } from './SpanHighLightsCode';
import { getDeepSearchFields, validateActions } from './utils';

interface ProfilerCardProps {
  data: Definitions.Identity;
  highlightValues: Highlight[];
  searchValue: string;
  rowIndex: number;
  isJson: boolean;
  onJsonChange: (index: string) => void;
  forceUpdate: (rowIndex: number, allowScroll?: boolean) => void;
  onLeakOpen: (data: Definitions.Leak) => void;
  getCountry: (code: string) => string;
}

export const ProfilerCard = forwardRef<HTMLDivElement, ProfilerCardProps>(function ProfilerCard(props, ref) {
  const { width } = useWindowSize();
  const offset = useMemo(() => Math.min(824 * 0.35, width * 0.4), [width]);

  return (
    <div ref={ref}>
      <ProfiledCardData {...props} offset={offset} />
    </div>
  );
});

type ProfiledCardDataProps = Omit<ProfilerCardProps, 'cache'> & {
  offset: number;
};

const renderPlainText = (lines: Lines): string => {
  let template = '';
  lines.forEach((line) => {
    if (line.type === 'separator') return;
    template +=
      line.type === 'collapsed'
        ? renderPlainText(line.data)
        : `\n${'\t'.repeat(line.level)}${line.key ? line.key + ':' : ''} ${line.value}`;
  });
  return template;
};

const ProfiledCardData = memo<ProfiledCardDataProps>(function ProfilerCardData({
  data,
  highlightValues,
  rowIndex,
  isJson,
  getCountry,
  onLeakOpen,
  forceUpdate,
  onJsonChange,
}) {
  const { t } = useTranslation();

  const hasAccessDeepSearch = useToolsPermission('recursive_search');

  const { leak, id, created_at, ...rest } = data;
  const previesJson = usePrevious(isJson);
  const { isOpen: isActionsMenuOpen, close: closeActionsMenu, toggle: onActionsMenuButtonClick } = useIsOpenControl();
  const { isOpen: isOpenDeepSearchForm, open: openDeepSearchForm, close: closeDeepSearchForm } = useIsOpenControl();

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    document.addEventListener('scroll-profiler-list', closeActionsMenu);

    return () => {
      document.removeEventListener('scroll-profiler-list', closeActionsMenu);
    };
  }, [closeActionsMenu]);

  const profilerValidatedKeys = useMemo(
    () =>
      PROFILER_VALIDATED_KEYS.filter(
        (key) =>
          !FILTERED_BY_COUNTRY_KEY.includes(key) ||
          (leak?.country && FILTERED_BY_COUNTRY_CODE.includes(leak?.country.toLocaleLowerCase()))
      ),
    [leak?.country]
  );

  const lines = useMemo(
    () => getLines.call({ inn: rest?.inn, profilerValidatedKeys }, filterData(cloneDeep(rest)), 0, ''),
    [rest, profilerValidatedKeys]
  );

  const copyText = useMemo(() => {
    // eslint-disable-next-line prefer-const
    let templateText = `
${leak?.title}
${t(`tooltips.trust`, {
  trust: t(`trust.${leak?.trust}`),
})}${leak?.country ? '\n' + leak.country : ''}
${moment.parseZone(leak?.released_at).format(process.env.REACT_APP_DATE_FORMAT)} ${t(`tooltips.date`)}
${renderPlainText(lines)}
`;

    return templateText;
  }, [lines, leak, t]);

  const copyTextJson = useMemo(
    () =>
      JSON.stringify(
        {
          leak,
          ...rest,
          created_at,
        },
        null,
        2
      ),
    [rest, leak, created_at]
  );

  const jsonText = useMemo(() => (isJson ? JSON.stringify(data, null, 2) : ''), [data, isJson]);

  const handleJsonChange = useCallback(() => {
    onJsonChange(data.id);
  }, [data.id, onJsonChange]);

  const handleForceUpdate = useCallback(() => {
    forceUpdate(rowIndex, false);
  }, [rowIndex, forceUpdate]);

  useEffect(() => {
    if (previesJson !== isJson) forceUpdate(rowIndex);
  }, [isJson, previesJson, rowIndex, forceUpdate]);

  const deepSearchData = useMemo(() => getDeepSearchFields(data), [data]);

  const actionsByData = useMemo(
    () =>
      [
        {
          component: (
            <EuiContextMenuItem key="deep_search" onClick={openDeepSearchForm} size="s">
              {t('actionsCard.deep_search')}
            </EuiContextMenuItem>
          ),
          key: 'deep_search',
          isAccess: hasAccessDeepSearch,
        },
        {
          component: (
            <EuiContextMenuItem key="validate_snils" size="s" href="https://es.pfrf.ru/checkSnils" target="_blank">
              {t('actionsCard.validate_snils')}
            </EuiContextMenuItem>
          ),
          key: 'validate_snils',
          isAccess: true,
        },
        {
          component: (
            <EuiContextMenuItem key="viewJson" onClick={handleJsonChange} size="s">
              {t(`button.${isJson ? 'viewText' : 'viewJson'}`)}
            </EuiContextMenuItem>
          ),
          key: 'viewJson',
          isAccess: true,
        },
        {
          component: (
            <CopyToClipboard
              key="copyJson"
              text={copyTextJson}
              options={{
                format: 'text/plain',
              }}
            >
              <EuiContextMenuItem key="copyJson" onClick={closeActionsMenu} size="s">
                {t('button.copyJson')}
              </EuiContextMenuItem>
            </CopyToClipboard>
          ),
          key: 'copyJson',
          isAccess: true,
        },
        {
          component: (
            <CopyToClipboard
              key="copy"
              text={copyText}
              options={{
                format: 'text/plain',
              }}
            >
              <EuiContextMenuItem onClick={closeActionsMenu} size="s">
                {t('button.copy')}
              </EuiContextMenuItem>
            </CopyToClipboard>
          ),
          key: 'copy',
          isAccess: true,
        },
        {
          component: (
            <EuiContextMenuItem
              key="print"
              onClick={() => {
                handlePrint();
                closeActionsMenu();
              }}
              size="s"
            >
              {t('button.print')}
            </EuiContextMenuItem>
          ),
          key: 'print',
          isAccess: true,
        },
      ]
        .filter(({ key, isAccess }) => isAccess && validateActions(data, key))
        .map(({ component }) => component),
    [
      closeActionsMenu,
      copyText,
      copyTextJson,
      data,
      handleJsonChange,
      handlePrint,
      hasAccessDeepSearch,
      isJson,
      openDeepSearchForm,
      t,
    ]
  );

  return (
    <>
      <EuiSplitPanel.Outer panelRef={componentRef} hasBorder={true} hasShadow={false} borderRadius={'m'}>
        <EuiSplitPanel.Inner color="subdued" grow={false}>
          <EuiFlexGroup gutterSize="none" alignItems="center" justifyContent="spaceBetween">
            <EuiFlexItem
              style={{
                minWidth: 0,
              }}
            >
              <EuiFlexGroup gutterSize="xs" alignItems="center">
                {leak?.trust && (
                  <EuiFlexItem grow={0}>
                    <EuiBadge
                      className={cn(
                        (() => {
                          const trustColors = getTrustColor(leak.trust);
                          if (!trustColors) return '';

                          return css(`
                          border-color: ${trustColors.border}!important;
                          background-color: ${trustColors.background}!important;
                          color: ${trustColors.text}!important;
                        `);
                        })(),
                        'trustBadge'
                      )}
                      aria-label="trust"
                      title={t(`tooltips.trust`, {
                        trust: t(`trust.${leak.trust}`),
                      })}
                    >
                      {leak.trust.toUpperCase()}
                    </EuiBadge>
                  </EuiFlexItem>
                )}
                {leak?.country && (
                  <EuiFlexItem grow={0}>
                    <LeakCountryBadge code={leak.country} getCountry={getCountry} />
                  </EuiFlexItem>
                )}
                {leak?.released_at && (
                  <EuiFlexItem grow={0}>
                    <EuiBadge
                      className={cn(
                        'leakText',
                        css(`
                          border-color: #D3DAE6!important;
                          margin: 0!important;
                        `)
                      )}
                      color="transparent"
                      title={t(`tooltips.date`)}
                    >
                      {moment.parseZone(leak.released_at).format(process.env.REACT_APP_DATE_FORMAT)}
                    </EuiBadge>
                  </EuiFlexItem>
                )}
                <EuiFlexItem
                  grow={1}
                  style={{
                    minWidth: 0,
                  }}
                >
                  <EuiText size="s">
                    <EuiLink
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        paddingRight: 16,
                        maxWidth: '100%',
                      }}
                      color={'primary'}
                      onClick={() => {
                        if (leak) onLeakOpen(leak);
                      }}
                    >
                      <b className="leakText">{leak?.title}</b>
                    </EuiLink>
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={0}>
              <EuiFlexGroup aria-label="print" gutterSize="s" alignItems="center">
                <EuiPopover
                  button={
                    <EuiButton
                      aria-label="Actions profiler card"
                      iconType={ActionIcon}
                      color="text"
                      onClick={onActionsMenuButtonClick}
                      size="s"
                      className={css(`
                        min-inline-size: 0;
                      `)}
                      disabled={actionsByData.length === 0}
                    >
                      {t('button.actions')}
                    </EuiButton>
                  }
                  isOpen={isActionsMenuOpen}
                  closePopover={closeActionsMenu}
                  panelPaddingSize="none"
                  anchorPosition="downLeft"
                >
                  <EuiContextMenuPanel size="s" items={actionsByData} />
                </EuiPopover>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiSplitPanel.Inner>
        <EuiSplitPanel.Inner
          style={{
            position: 'relative',
          }}
          grow={true}
        >
          {isJson ? (
            <>
              <SpanHighLightsCode highlights={highlightValues}>
                <EuiCodeBlock lineNumbers language="json" transparentBackground paddingSize="none">
                  {jsonText}
                </EuiCodeBlock>
              </SpanHighLightsCode>
            </>
          ) : (
            <CardData
              data={lines}
              id={id}
              highlights={highlightValues}
              className="profilerCardData"
              data-id={id}
              forceUpdate={handleForceUpdate}
            />
          )}
        </EuiSplitPanel.Inner>
      </EuiSplitPanel.Outer>
      <EuiSpacer size="m" />
      {isOpenDeepSearchForm && deepSearchData && (
        <CreateRecursiveSearchTask
          onSuccess={closeDeepSearchForm}
          onClose={closeDeepSearchForm}
          defaultData={{
            search_fields: deepSearchData,
            title: `Profiler card #${data.id}`,
          }}
        />
      )}
    </>
  );
});
