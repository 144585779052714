import { FunctionComponent } from 'react';

import { CardDataLineProps, CardDataProps } from './types';
import { FormattedLine } from './FormattedLine';
import { CollapseLine } from './CollapseLine';
import { SeparatorLine } from './SeparatorLine';

import './styles.scss';

export const CardDataLine: FunctionComponent<CardDataLineProps> = ({ data, highlights, ...props }) => {
  switch (data.type) {
    case 'line':
      return <FormattedLine data={data} highlights={highlights} />;
    case 'separator':
      return <SeparatorLine />;
    case 'collapsed':
      return <CollapseLine data={data} {...props} />;
    default:
      return null;
  }
};

export const CardData: FunctionComponent<CardDataProps> = ({
  id,
  data,
  highlights,
  className = 'cardData',
  forceUpdate,
}) => {
  return (
    <div className={className}>
      {data.map((lineData, index) => (
        <CardDataLine
          key={`${id}-${index}`}
          id={id}
          data={lineData}
          highlights={highlights}
          forceUpdate={forceUpdate}
        />
      ))}
    </div>
  );
};
