import { SchemaOf, object, string } from 'yup';

export type FormFields = Paths.EditProfilerLeaks.Parameters.Body & Paths.EditProfilerLeaks.PathParameters;

export const trusts = ['l', 'm', 'h', 'f'];

export const schema: SchemaOf<FormFields> = object().shape({
  id: string().required(),
  description: string().required('form.error.required'),
  trust: string().oneOf(trusts).required('form.error.required'),
});
