import { EuiProvider, EuiThemeColorMode } from '@elastic/eui';
import { css, Global } from '@emotion/react';
import { FC, useCallback, useEffect, useState } from 'react';

import { Apps } from '@/hooks';

import { Theme } from './context';

const LOCAL_STORAGE_KEY = 'ui_theme';

const initMode = (): EuiThemeColorMode => {
  let theme = localStorage.getItem(LOCAL_STORAGE_KEY) as EuiThemeColorMode;

  if (!theme) {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme:dark)').matches) {
      theme = 'DARK';
    } else {
      theme = 'LIGHT';
    }
  }
  return theme;
};

export interface ThemeExtensions {
  colors: {
    headerDelimiter: string;
    tools: Record<Apps, string>;
  };
}

const toolsColors: Record<Apps, string> = {
  [Apps.PASSWORD_FINDER]: '#00BFB3',
  [Apps.PROFILER]: '#C4407C',
  [Apps.LEAK_REGISTRY]: '#FEC514',
  [Apps.PHONEBOOK_PARSER]: '#F86B63',
  [Apps.MAP_RADAR]: '#0818AA',
  [Apps.DATASETS]: '#974CF7',
  [Apps.RECURSIVE_SEARCH]: '#2eae4f',
  [Apps.PROFILER_2]: '#C4407C',
};

const primaryOverrides = {
  colors: {
    LIGHT: {
      headerDelimiter: '#343741',
      tools: toolsColors,
    },
    DARK: {
      headerDelimiter: '#FFFFFF33',
      tools: toolsColors,
    },
  },
};

export const ThemeProvider: FC = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<EuiThemeColorMode>(initMode());

  const handleChangeTheme = useCallback((theme: EuiThemeColorMode) => {
    setCurrentTheme(theme);
    localStorage.setItem(LOCAL_STORAGE_KEY, theme);
  }, []);

  useEffect(() => {
    const meta = document.querySelector('meta[name="eui-theme-insert"]');
    if (meta) {
      const style = document.createElement('link');
      style.href = `/themes/eui_theme_${currentTheme === 'DARK' ? 'dark' : 'light'}.min.css`;
      style.rel = 'stylesheet';
      meta.appendChild(style);

      const id = setTimeout(() => {
        meta.childNodes.forEach((node) => {
          if (meta.children.length > 1) {
            meta.removeChild(node);
          }
          return () => clearTimeout(id);
        });
      }, 1000);
    }
  }, [currentTheme]);

  return (
    <Theme.Provider
      value={{
        colorMode: currentTheme,
        onChangeTheme: handleChangeTheme,
      }}
    >
      <EuiProvider colorMode={currentTheme} modify={primaryOverrides}>
        {children}
        <Global
          styles={css(
            currentTheme === 'DARK'
              ? `
          :root {
            --color-row-invalid:  rgba(189, 39, 30, 0.17);
            --color-row-invalid-hover:  rgba(189, 39, 30, 0.3);
            --color-row-valid: rgba(0, 191, 179, 0.17);
            --color-row-valid-hover: rgba(0, 191, 179, 0.3);
            --color-row-progress: rgba(255, 255, 255, 0.115);
            --color-row-progress-hover: #2e2d25;
            --color-row-delimiter: #141519;

            --profiler-text-key: #69707D;
            --profiler-text-key-first: #36a2ef;
            --profiler-text-array: #FFFFFF;
            --profiler-text: #FFFFFF;

            --color-hightlighter: rgba( 255,  255, 0, 0.5);
            --color-hightlighter-double: rgb(255, 117, 0, 0.3);

            --leaflet-button-delimiter: #4e4e4e;
            --color-accent-text: #6EE7B7;
            --color-accent-shadow: rgba(110, 231, 183, 0.5);
            --bg-tooltip: #ECECF1;
            --color-accent-title: #EDEDED;
            --bg-color-app-card: #1D1E24;

            --react-international-phone-background-color: #141519;
            --react-international-phone-text-color: #FFFFFF;
            --react-international-phone-border-color: #343741;
            --react-international-phone-selected-dropdown-item-background-color: #343741;
          }
        `
              : `
          :root {
            --color-row-invalid:  rgba(189, 39, 30, 0.07);
            --color-row-invalid-hover:  rgba(189, 39, 30, 0.2);
            --color-row-valid: rgba(0, 191, 179, 0.07);
            --color-row-valid-hover: rgba(0, 191, 179, 0.2);
            --color-row-progress: rgba(0,119,204,0.1);
            --color-row-progress-hover: #fff9e8;
            --color-row-delimiter: #fff;

            --profiler-text-key: #69707D;
            --profiler-text-key-first: #005EC4;
            --profiler-text-array: #69707D;
            --profiler-text: #343741;

            --color-hightlighter: rgba( 255,  255, 0, 0.5);
            --color-hightlighter-double: rgb(255, 117, 0, 0.5);

            --leaflet-button-delimiter: #e3e3e3;
            --color-accent-text: #3143E4;
            --color-accent-shadow: rgba(49, 67, 228, 0.5);
            --bg-tooltip: #ECECF1;
            --color-accent-title: #3143E4;
            --bg-color-app-card: #fff;

            --react-international-phone-background-color: #fff;
            --react-international-phone-text-color: #343741;
            --react-international-phone-border-color: #e3e3e3;
            --react-international-phone-selected-dropdown-item-background-color: #e3e3e3;
          }
        `
          )}
        />
      </EuiProvider>
    </Theme.Provider>
  );
};
