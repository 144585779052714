class ImageCache {
  private data: Record<string, string> = {};

  set(key: string, value: string) {
    this.data[key] = value;
  }

  get(key: string) {
    return this.data[key] || null;
  }
}

export const imageCache = new ImageCache();
